import React, { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";

import LastActivitiesPreloader from "./LastActivitiesPreloader";
import LastActivitiesHeader from "./LastActivitiesHeader";
import EmptyDashboardWidgets from "../EmptyDashboadWidgets";
import LastActivitiesTable from "./LastActivitiesTable";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import TABLE_TYPE from "./model/mockData";
import Filters from "./Filters";
import { LAST_ACTIVITIES_HEAD } from "models/dashboard/tables";
import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";
import { getAgreementsMarkets } from "store/agreements/actions";
import {
  CustomScrollbars,
  Error,
  PaginationPanel,
  SortTableHead,
} from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";

const getEmptyTitle = filter =>
  filter ? `No ${filter} agreements found` : "No agreements found";

const LastActivities = ({ dashboardLoading, tableView }) => {
  const tableConfig = TABLE_TYPE[tableView];
  const { loading, data, error } = useSelector(tableConfig.selector);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(getAgreementsMarkets());
  }, [dispatch]);

  const { handlePaginate, handleSorting, handleFilter, filters } =
    useFiltersWithRequest(data?.request, tableConfig.action);

  if (error) return <Error error={tableConfig.errorState.title} />;
  if (!loading && dashboardLoading) return <LastActivitiesPreloader />;

  return (
    <div ref={wrapperRef}>
      <Card>
        <LastActivitiesHeader
          title={tableConfig.header.title}
          linkTo={tableConfig.header.linkTo}
          linkText={tableConfig.header.linkText}
        />
        {tableView === "agreements" && (
          <Filters
            filter={data?.request?.filter || filters}
            handleFilter={handleFilter}
            dataMapping={tableConfig}
            loadingPage={loading}
          />
        )}
        {loading ? (
          <div className='overflow-hidden'>
            <LastActivitiesPreloader table />
          </div>
        ) : (
          <CardBody className='p-0'>
            {data?.items?.length ? (
              <CustomScrollbars
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                autoHeightMax={Number.MAX_VALUE}
              >
                <StyledTable className='mb-0 font-sm'>
                  <SortTableHead
                    handleSorting={handleSorting}
                    sort={data?.request?.sort}
                    theadItems={LAST_ACTIVITIES_HEAD}
                  />
                  <tbody>
                    {data?.items?.map(item => {
                      const dataMapping = tableConfig.dataMapping(item);
                      const path = tableConfig.header.path;
                      return (
                        <LastActivitiesTable
                          key={item.id}
                          item={item}
                          invested={dataMapping?.invested}
                          code={dataMapping?.code}
                          profit={dataMapping?.profit}
                          profitPercent={dataMapping?.profitPercent}
                          itemPath={path}
                        />
                      );
                    })}
                  </tbody>
                </StyledTable>
              </CustomScrollbars>
            ) : (
              <EmptyDashboardWidgets
                className='pb-4'
                Img={Empty}
                title={
                  tableView === "agreements"
                    ? getEmptyTitle(data?.request?.filter?.status?.eq)
                    : tableConfig.empty.title
                }
                descriptionBefore={tableConfig.empty.descriptionBefore}
              />
            )}
            <div className={`${data?.total > 10 ? "mt-3" : ""}`}>
              <PaginationPanel
                activePage={data?.page}
                totalItemsCount={data?.total}
                onChange={handlePaginate}
                elementRef={wrapperRef}
                isCircle
              />
            </div>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default LastActivities;
