import React, { useState } from "react";
import styled from "styled-components";
import { Col, Tooltip } from "reactstrap";

import { indicatorsConfiguration } from "views/bots/wizard/Configuration/Indicators/models/indicatorsConfiguration";
import { BadgeSignal } from "../badges";
import { ReactComponent as Information } from "assets/icons/general/information.svg";

const indicatorName = indicatorsConfiguration.filter(({ value }) => value);

const Indicator = ({ indicator }) => {
  const [isShow, setIsShow] = useState(false);
  const toggleShow = () => setIsShow(!isShow);

  const settings = Object.values(indicator.settings).join(", ");

  return (
    <Indicator.Wrapper
      className='d-block d-flex flex-row align-items-center position-relative'
      required={indicator.required}
    >
      <Col
        xs={10}
        md={8}
        className='d-flex flex-column justify-content-start px-0 text-white'
      >
        <div className='d-flex align-items-center'>
          <div className='mr-2'>
            <div
              className='position-relative necessary-signal mr-0'
              id={`indicator-${indicator.id}`}
            >
              <Information />
            </div>
            <Tooltip
              target={`indicator-${indicator.id}`}
              placement='top-start'
              isOpen={isShow}
              toggle={toggleShow}
              className='text-nowrap'
            >
              Necessary indicator for trade request
            </Tooltip>
          </div>
          <Indicator.indicatorName className='text-white text-truncate text-uppercase font-weight-500 mb-0 font-sm mr-3'>
            {indicatorName.map(
              item => indicator.code === item.value && item.label,
            )}
          </Indicator.indicatorName>
          <p className='text-truncate mb-0 text-white-35 font-weight-400 font-xs'>
            {settings}
          </p>
        </div>
      </Col>
      <Col
        xs={2}
        md={4}
        className='d-flex flex-row justify-content-end px-0 mr-0 ml-auto'
      >
        <BadgeSignal signal={indicator.type} />
      </Col>
    </Indicator.Wrapper>
  );
};

Indicator.Wrapper = styled.div`
  border-top: 1px solid #2e3148;
  padding: 0.75rem 1rem;

  .necessary-signal {
    width: 24px;
    cursor: help;
    svg {
      path {
        stroke: ${props =>
          props.required ? "var(--warning)" : "rgba(255, 255, 255, 0.35)"};
        transition: 0.3s all ease-in-out;
      }
    }
  }
`;

Indicator.indicatorName = styled.p`
  @media screen and (max-width: 480px) {
    width: 140px;
  }
`;

export default Indicator;
