import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import useWindowDimensions from "hooks/useWindowDimensions";
import { paginationScrollUp } from "helpers/paginationScrollUp";
import WelcomeStep from "./steps/WelcomeStep";
import ExchangeStep from "./steps/ExchangeStep";
import Navigation from "./compounds/Navigation";
import StepsNavigation from "./compounds/StepsNavigation";
import ConnectStep from "./steps/ConnectStep";
import TryForFree from "./steps/TryForFree";
import ConfirmModal from "./compounds/ConfirmModal";
import { skipWizard } from "store/user/actions";
import backgroundImage from "assets/img/mainWizard/wizard-background.png";

const MainWizard = ({ setShouldShowWizard }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [exchange, setExchange] = useState("");
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const [exchangeName, setExchangeName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({ apiKey: "", secret: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { height } = useWindowDimensions();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleScrollUp = () => {
    if (height < 862) {
      paginationScrollUp();
    }
  };

  const handleSkip = (redirectPath = "/") => {
    setIsLoading(true);
    dispatch(skipWizard())
      .then(() => {
        history.push(redirectPath);
        setShouldShowWizard(false);
      })
      .catch(() => setIsLoading(false));
  };

  const handleMoveToNextStep = () => {
    if (isCurrentStepValid) setCurrentStep(prev => prev + 1);
    handleScrollUp();
  };

  const getCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <WelcomeStep
            setIsCurrentStepValid={setIsCurrentStepValid}
            handleMoveToNextStep={handleMoveToNextStep}
          />
        );

      case 2:
        return (
          <ExchangeStep
            currentStep={currentStep}
            setIsCurrentStepValid={setIsCurrentStepValid}
            setCurrentStep={setCurrentStep}
            handleScrollUp={handleScrollUp}
            setExchange={setExchange}
            exchange={exchange}
            exchangeName={exchangeName}
            websiteLink={websiteLink}
            setExchangeName={setExchangeName}
            setWebsiteLink={setWebsiteLink}
            handleMoveToNextStep={handleMoveToNextStep}
            isCurrentStepValid={isCurrentStepValid}
          />
        );

      case 3:
        return (
          <ConnectStep
            currentStep={currentStep}
            setIsCurrentStepValid={setIsCurrentStepValid}
            setCurrentStep={setCurrentStep}
            handleScrollUp={handleScrollUp}
            exchange={exchange}
            setFormData={setFormData}
            formData={formData}
            websiteLink={websiteLink}
            exchangeName={exchangeName}
          />
        );

      case 4:
        return <TryForFree onSetModalIsOpen={setModalIsOpen} />;

      default:
        return null;
    }
  };

  const handleSetCurrentStep = step => {
    const cantMoveToStep =
      (!isCurrentStepValid && step >= currentStep) || step > currentStep + 1;
    handleScrollUp();
    if (cantMoveToStep) return;
    setCurrentStep(step);
  };

  return (
    <WizardWrapper>
      <Navigation
        handleSetCurrentStep={handleSetCurrentStep}
        currentStep={currentStep}
        onSetModalIsOpen={setModalIsOpen}
      />
      <BodySection className='d-flex flex-column justify-content-md-center align-items-center'>
        <div className='container'>
          <StepCard className='position-relative mb-5'>
            {getCurrentStep()}
          </StepCard>
        </div>
      </BodySection>
      <div className='d-flex d-md-none justify-content-center my-4'>
        <StepsNavigation
          handleSetCurrentStep={handleSetCurrentStep}
          currentStep={currentStep}
        />
      </div>
      <ConfirmModal
        onSetModalIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        handleSkip={handleSkip}
        isLoading={isLoading}
      />
    </WizardWrapper>
  );
};

const WizardWrapper = styled.div`
  background: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  h1 {
    color: var(--dark);
  }

  p {
    color: var(--dark-75);
  }
`;

const BodySection = styled.div`
  @media (min-width: 768px) {
    min-height: calc(100vh - 140px);
  }
`;

const StepCard = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.16);
  min-height: 496px;

  @media (max-width: 768px) {
    min-height: 700px;
  }
`;

export default MainWizard;
